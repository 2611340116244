import React from "react";
import Gdpr from "@/components/jp/gdpr";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from "@mui/icons-material/Circle";
import Link from "next/link";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import { useRouter } from "next/router";

function Footer() {
  const [domLoaded, setDomLoaded] = React.useState(false);
  React.useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row">
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    flexGrow: 1,
                    alignItems: "center",
                  }}
                >
                  <h5 className="fw-bold">加工・用途紹介</h5>
                  <List>
                    <Link href="/processusage#stamping">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CircleIcon
                            sx={{ fontSize: "10px", color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="プレス加工品" />
                      </ListItem>
                    </Link>
                    <Link href="/processusage#machining">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CircleIcon
                            sx={{ fontSize: "10px", color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="切削加工品" />
                      </ListItem>
                    </Link>
                    <Link href="/processusage#coloranodized">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CircleIcon
                            sx={{ fontSize: "10px", color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="アルマイト着色加工品" />
                      </ListItem>
                    </Link>
                    {/* <Link href="/processusage#subprocesses">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CircleIcon
                            sx={{ fontSize: "10px", color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Sub Processes" />
                      </ListItem>
                    </Link> */}
                    <Link href="/processusage#plantinfrastructure">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CircleIcon
                            sx={{ fontSize: "10px", color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="プラント・インフラ向け機器" />
                      </ListItem>
                    </Link>
                  </List>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    flexGrow: 1,
                    alignItems: "center",
                  }}
                >
                  <h5 className="fw-bold">設備概要</h5>
                  <List>
                    <Link href="/instrument">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CircleIcon
                            sx={{ fontSize: "10px", color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="プレス加工" />
                      </ListItem>
                    </Link>
                    <Link href="/instrument">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CircleIcon
                            sx={{ fontSize: "10px", color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="切削加工" />
                      </ListItem>
                    </Link>
                    <Link href="/instrument">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CircleIcon
                            sx={{ fontSize: "10px", color: "#fff" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="アルマイト加工" />
                      </ListItem>
                    </Link>
                  </List>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    flexGrow: 1,
                    alignItems: "center",
                  }}
                >
                  <Link href="/news">
                    <h5 className="fw-bold mb-3">ニュース</h5>
                  </Link>
                  <Link href="/aboutus">
                    <h5 className="fw-bold mb-3">会社概要</h5>
                  </Link>
                  <Link href="/companynetwork">
                    <h5 className="fw-bold mb-5">関連会社紹介</h5>
                  </Link>
                  <Link href="/privacypolicy">
                    <h6 className="fw-normal mb-3">Privacy Policy</h6>
                  </Link>
                  <Link href="/termsofuse">
                    <h6 className="fw-normal mb-3">Terms of Use</h6>
                  </Link>
                  <Link href="/cookie">
                    <h6 className="fw-normal">Cookies Details Page</h6>
                  </Link>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    flexGrow: 1,
                    alignItems: "center",
                    textAlign: "end",
                  }}
                  className="footer-contact"
                >
                  <h5 className="fw-bold">ZENIYA (THAILAND) CO., LTD.</h5>
                  <h5 className="mb-3">ゼニヤタイランド</h5>
                  <p className="mb-4">
                    Amata City Chonburi Industrial Estate Phase 8 <br />
                    700/750 Moo 1, Phan Thong, Chonburi 20160 <br />
                    Thailand
                  </p>

                  <Link href="/contactus">
                    <Button
                      sx={{ px: 5, py: 1 }}
                      variant="contained"
                      endIcon={<EmailIcon />}
                    >
                      お問い合わせ
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p className="text-center mb-0">
          Copyright © 2023 ZENIYA (THAILAND) CO.,LTD. All rights reserved.
        </p>
      </div>

      {domLoaded && <Gdpr />}
    </footer>
  );
}

export default Footer;
