import React from "react";
import SearchInput from "@/components/SearchInput";
import Image from "next/image";
import Logo from "@/public/static/images/logo.png";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Link from "next/link";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRouter } from "next/router";

const drawerWidth = 240;
const navItems = [
  { link: "/", component: "ホーム" },
  { link: "/processusage", component: "加工・用途紹介" },
  { link: "/instrument", component: "設備概要" },
  { link: "/news", component: "ニュース" },
  { link: "/aboutus", component: "会社概要" },
  { link: "/companynetwork", component: "関連会社紹介" },
  { link: "/contactus", component: "お問い合わせ" },
];

function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const router = useRouter();
  const { pathname, asPath, query } = router;
  const onChangeLanguage = (lang: any) => (e: any) => {
    e.preventDefault();
    var url: any = router.asPath.split("/");
    if (url[1] == "NewsDetail") {
      router.push("/" + lang + "/news", undefined, { locale: lang });
    } else {
      router.push(lang + "/" + router.asPath, undefined, { locale: lang });
    }
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Divider />
      <List>
        {navItems.map((item, index) => {
          return (
            <Link href={item.link} key={index}>
              <ListItem key={index}>{item.component}</ListItem>
            </Link>
          );
        })}
      </List>
    </Box>
  );

  return (
    <header id="back-to-top-anchor">
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MZ697FNQ"
          height="0"
          width="0"
          className="invisible d-none"
        ></iframe>
      </noscript>
      <div className="container">
        <div className="row">
          <Toolbar sx={{ py: 2 }} className="toolbar">
            <Box
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, width: 400 }}
            >
              <Image
                src={Logo}
                alt="Zeniya"
                className="logo"
                width={300}
                loading="lazy"
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              <SearchInput />
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                sx={{ alignItems: "center", ml: 4 }}
                className="lang-button"
              >
                <Link href="/" className="active">
                  JP
                </Link>
                <Link href="/en/" onClick={onChangeLanguage("en")}>
                  EN
                </Link>
                <Link href="/th/" onClick={onChangeLanguage("th")}>
                  TH
                </Link>
              </Stack>
            </Box>

            <Box sx={{ flexGrow: 0 }}></Box>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Box className="mo-logo" sx={{ mr: 1, width: "40%" }}>
                <Link href="/">
                  <Image
                    src={Logo}
                    alt="Zeniya"
                    className="logo"
                    width={100}
                    loading="lazy"
                  />
                </Link>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: { xs: "none", sm: "block", md: "none" } }}>
                  <SearchInput />
                </Box>

                <Button
                  onClick={handleClick}
                  size="small"
                  sx={{ mr: 2 }}
                  aria-controls={open ? "lang-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="outlined"
                  className="mb-lang"
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  JP
                </Button>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                  className="mb_menu"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>

            <Menu
              anchorEl={anchorEl}
              id="lang-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Link href="/en/" onClick={onChangeLanguage("en")}>
                  EN
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/th/" onClick={onChangeLanguage("th")}>
                  TH
                </Link>
              </MenuItem>
            </Menu>

            <Box component="nav">
              <Drawer
                className="drawer"
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { sm: "block", md: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                  "& .MuiPaper-root": {
                    backgroundColor: "#001736",
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
          </Toolbar>
        </div>
      </div>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <SearchInput />
      </Box>
    </header>
  );
}

export default Header;
