import React from "react";
import Box from "@mui/material/Box";
import Link from "next/link";
import { useRouter } from "next/router";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";

const navItems = [
  { link: "/", component: <HomeIcon /> },
  { link: "/processusage", component: "加工・用途紹介" },
  { link: "/instrument", component: "設備概要" },
  { link: "/news", component: "ニュース" },
  { link: "/aboutus", component: "会社概要" },
  { link: "/companynetwork", component: "関連会社紹介" },
  { link: "/contactus", component: "お問い合わせ" },
];

function Menu() {
  const router = useRouter();
  return (
    <div className="menu">
      <div className="container">
        <div className="row">
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {navItems.map((item, index) => {
              return (
                <Link href={item.link} key={index}>
                  <Button
                    key={index}
                    sx={
                      router.pathname === item.link
                        ? {
                            backgroundColor: "#1565c0",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#0179CC",
                              color: "#fff",
                            },
                          }
                        : { color: "#fff" }
                    }
                  >
                    {item.component}
                  </Button>
                </Link>
              );
            })}
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Menu;
